//  This is a generated file, do not edit it directly
import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgUsdt } from '../icons/Usdt';

export function UsdtIcon(props: Omit<SvgIconProps, 'component' | 'children'>): React.ReactElement {
  return (
    <SvgIcon {...props} component={SvgUsdt} data-qa="UsdtIcon">
      <svg />
    </SvgIcon>
  );
}
