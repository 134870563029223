import type { SVGProps } from 'react';

export function SvgUnitedStates(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10"
        fill="#F0F0F0"
      />
      <path
        d="M11.565 12H22c0-.902-.12-1.777-.345-2.609h-10.09zm0-5.217h8.967a10 10 0 0 0-2.307-2.609h-6.66zM12 22a9.96 9.96 0 0 0 6.225-2.174H5.775A9.96 9.96 0 0 0 12 22m-8.532-4.782h17.064a10 10 0 0 0 1.123-2.61H2.345a10 10 0 0 0 1.123 2.61"
        fill="#D80027"
      />
      <path
        d="M6.632 3.562h.911l-.847.615.324.997-.848-.616-.848.616.28-.86c-.746.62-1.4 1.35-1.94 2.16h.293l-.54.393q-.126.21-.242.427l.258.793-.481-.35a10 10 0 0 0-.327.777l.284.874h1.048l-.848.616.324.996-.848-.616-.508.37Q2.001 11.365 2 12h10V2a9.95 9.95 0 0 0-5.368 1.562M7.02 11l-.848-.616-.848.616.324-.996-.847-.616h1.047l.324-.997.324.997h1.047l-.847.616zm-.324-3.91.324.997-.848-.616-.848.616.324-.997-.847-.615h1.047l.324-.997.324.997h1.047zm3.91 3.91-.847-.616L8.91 11l.324-.996-.848-.616h1.048l.324-.997.324.997h1.047l-.847.616zm-.323-3.91.324.997-.848-.616-.848.616.324-.997-.848-.615h1.048l.324-.997.324.997h1.047zm0-2.913.324.997-.848-.616-.848.616.324-.997-.848-.615h1.048l.324-.997.324.997h1.047z"
        fill="#0052B4"
      />
    </svg>
  );
}
