import { BitcoinIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/BitcoinIcon';
import { EthereumIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/EthereumIcon';
import { LightningCircleIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/LightningCircleIcon';
import { NoahCurrencyIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/NoahCurrencyIcon';
import { PolygonIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/PolygonIcon';
import { TronIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/TronIcon';
import { UsdcoinIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/UsdcoinIcon';
import { UsdtIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/UsdtIcon';
import { mockCryptoCurrenciesFromApi } from '@noah-labs/shared-currencies/src/crypto-currencies';
import type { CurrencyCode } from '@noah-labs/shared-schema-gql';
// will remove deepmerge in next PR
import deepmerge from 'deepmerge';
import type { TpCryptoCurrencyUI } from './types';

const currencyIcons: Record<CurrencyCode, Pick<TpCryptoCurrencyUI, 'Icon' | 'NetworkIcons'>> = {
  BTC: {
    Icon: BitcoinIcon,
    NetworkIcons: { Bitcoin: BitcoinIcon, Lightning: LightningCircleIcon },
  },
  BTC_TEST: {
    Icon: BitcoinIcon,
    NetworkIcons: { BitcoinTest: BitcoinIcon, LightningTest: LightningCircleIcon },
  },
  ETH: { Icon: EthereumIcon, NetworkIcons: { Ethereum: EthereumIcon } },
  ETH_TEST_SEPOLIA: { Icon: EthereumIcon, NetworkIcons: { Ethereum: EthereumIcon } },
  MATIC: { Icon: null, NetworkIcons: null },
  MATIC_TEST: { Icon: null, NetworkIcons: null },
  NOAH: { Icon: NoahCurrencyIcon, NetworkIcons: null },
  TRX: { Icon: null, NetworkIcons: null },
  TRX_TEST: { Icon: null, NetworkIcons: null },
  USDC: {
    Icon: UsdcoinIcon,
    NetworkIcons: { Ethereum: EthereumIcon, PolygonPos: PolygonIcon, Tron: TronIcon },
  },
  USDC_TEST: {
    Icon: UsdcoinIcon,
    NetworkIcons: {
      EthereumTestSepolia: EthereumIcon,
      PolygonTestMumbai: PolygonIcon,
      TronTestShasta: TronIcon,
    },
  },
  USDT: {
    Icon: UsdtIcon,
    NetworkIcons: { Ethereum: EthereumIcon, PolygonPos: PolygonIcon, Tron: TronIcon },
  },
  USDT_TEST: {
    Icon: UsdtIcon,
    NetworkIcons: {
      EthereumTestSepolia: EthereumIcon,
      PolygonTestMumbai: PolygonIcon,
      TronTestShasta: TronIcon,
    },
  },
};

const cryptoCurrenciesWithIcons = deepmerge(currencyIcons, mockCryptoCurrenciesFromApi);

export function cryptoCurrencyFromCode(code: CurrencyCode): TpCryptoCurrencyUI {
  return cryptoCurrenciesWithIcons[code];
}
