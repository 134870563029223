import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { PpWC } from '../types';

const StyledBulletedList = styled(Box)(({ theme }) => ({
  '& li': {
    paddingLeft: theme.spacing(0.5),
  },
  padding: theme.spacing(0, 0, 0, 3),
}));

export function BulletedList({ children }: PpWC): React.ReactElement {
  return <StyledBulletedList component="ul">{children}</StyledBulletedList>;
}
