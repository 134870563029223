import type { ReactElement } from 'react';
import type { StackProps } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import { List as MuiList, Stack } from '@mui/material';
import type { PpWC } from '../types';

type PpListWithSpacing = {
  disablePadding?: boolean;
} & Pick<StackProps, 'spacing' | 'sx' | 'direction'> &
  PpWC;

export function List({
  children,
  direction = 'column',
  disablePadding = false,
  spacing,
  sx,
}: PpListWithSpacing): ReactElement {
  return (
    <Stack
      component={MuiList}
      direction={direction}
      disablePadding={disablePadding}
      spacing={spacing}
      sx={sx}
    >
      {children}
    </Stack>
  );
}
