import type { SVGProps } from 'react';

export function SvgUnitedKingdom(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10"
        fill="#F0F0F0"
      />
      <path
        d="M4.067 5.912a10 10 0 0 0-1.723 3.48h5.203zm17.588 3.479a10 10 0 0 0-1.722-3.48l-3.48 3.48zM2.344 14.609a10 10 0 0 0 1.723 3.48l3.48-3.48zM18.088 4.067a10 10 0 0 0-3.48-1.723v5.203zM5.912 19.933a10 10 0 0 0 3.48 1.723v-5.203zM9.391 2.344a10 10 0 0 0-3.48 1.723l3.48 3.48zm5.218 19.311a10 10 0 0 0 3.48-1.722l-3.48-3.48zm1.844-7.046 3.48 3.48a10 10 0 0 0 1.722-3.48z"
        fill="#0052B4"
      />
      <path
        d="M21.915 10.696h-8.61V2.085a10 10 0 0 0-2.61 0v8.61h-8.61a10 10 0 0 0 0 2.61h8.61v8.61a10 10 0 0 0 2.61 0v-8.61h8.61a10 10 0 0 0 0-2.61Z"
        fill="#D80027"
      />
      <path
        d="m14.609 14.609 4.462 4.462q.307-.308.588-.642l-3.82-3.82zm-5.218 0L4.93 19.07q.308.307.642.588l3.82-3.82zm0-5.218L4.93 4.93q-.307.308-.588.642l3.82 3.82zm5.218 0L19.07 4.93a10 10 0 0 0-.642-.588l-3.82 3.82z"
        fill="#D80027"
      />
    </svg>
  );
}
