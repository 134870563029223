import type { SVGProps } from 'react';

export function SvgLightning(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.52 12.311 16.48 1.144c.566-.362 1.104 0 .76.62l-4.137 8.135h7.376s1.172 0 0 .965L7.726 22.1c-.896.758-1.517.345-.896-.827l3.998-7.928H3.521s-1.172 0 0-1.034Z"
        fill="currentColor"
      />
    </svg>
  );
}
