import { AppEnvs, CurrencyCode } from '@noah-labs/shared-schema-gql';

function isValidCurrency(value: unknown): value is CurrencyCode {
  return Object.values(CurrencyCode).includes(value as CurrencyCode);
}

export function getEnvCurrencyCode(
  code: CurrencyCode | string | undefined,
  isProd: boolean
): CurrencyCode {
  const codeUpper = code?.toUpperCase();
  if (!isValidCurrency(codeUpper)) {
    throw new Error('currency is not supported');
  }

  const ccTestMap: Record<CurrencyCode, CurrencyCode> = {
    BTC: CurrencyCode.BTC_TEST,
    BTC_TEST: CurrencyCode.BTC_TEST,
    ETH: CurrencyCode.ETH_TEST_SEPOLIA,
    ETH_TEST_SEPOLIA: CurrencyCode.ETH_TEST_SEPOLIA,
    MATIC: CurrencyCode.MATIC_TEST,
    MATIC_TEST: CurrencyCode.MATIC_TEST,
    NOAH: CurrencyCode.NOAH,
    TRX: CurrencyCode.TRX_TEST,
    TRX_TEST: CurrencyCode.TRX_TEST,
    USDC: CurrencyCode.USDC_TEST,
    USDC_TEST: CurrencyCode.USDC_TEST,
    USDT: CurrencyCode.USDT_TEST,
    USDT_TEST: CurrencyCode.USDT_TEST,
  };
  const ccProdMap: Record<CurrencyCode, CurrencyCode> = {
    BTC: CurrencyCode.BTC,
    BTC_TEST: CurrencyCode.BTC,
    ETH: CurrencyCode.ETH,
    ETH_TEST_SEPOLIA: CurrencyCode.ETH,
    MATIC: CurrencyCode.MATIC,
    MATIC_TEST: CurrencyCode.MATIC,
    NOAH: CurrencyCode.NOAH,
    TRX: CurrencyCode.TRX,
    TRX_TEST: CurrencyCode.TRX,
    USDC: CurrencyCode.USDC,
    USDC_TEST: CurrencyCode.USDC,
    USDT: CurrencyCode.USDT,
    USDT_TEST: CurrencyCode.USDT,
  };

  return isProd ? ccProdMap[codeUpper] : ccTestMap[codeUpper];
}

export function getAppEnvCurrencyCode(code: CurrencyCode, env: AppEnvs): CurrencyCode {
  return getEnvCurrencyCode(code, env === AppEnvs.Prod);
}
