import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import type { TypographyProps } from '@mui/material';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { TextOrSkeleton } from '../typography/TextOrSkeleton';

// https://day.js.org/docs/en/plugin/duration
dayjs.extend(duration);

export type PpCountdown = {
  enabled: boolean;
  expiredLabel?: string;
  expiryTime: string | number | null | undefined;
  format: string;
  initialTimeDiff: number;
  prefixLabel?: string;
  suffixLabel?: string;
};

function getTimeDiff(expiryTime: string | number | null | undefined): number {
  return dayjs(expiryTime).diff(dayjs());
}

/**
 * format: https://day.js.org/docs/en/display/format
 */
export function Countdown({
  enabled = true,
  expiredLabel,
  expiryTime,
  format,
  initialTimeDiff,
  prefixLabel,
  suffixLabel,
  ...typographyProps
}: PpCountdown & TypographyProps): React.ReactElement {
  const [timeDiff, setTimeDiff] = useState<number>(initialTimeDiff);

  useEffect((): void => {
    if (!enabled) {
      return;
    }
    setTimeDiff(getTimeDiff(expiryTime));
  }, [enabled, expiryTime]);

  useEffect(() => {
    if (!enabled) {
      return undefined;
    }

    const interval = setInterval(() => {
      setTimeDiff(getTimeDiff(expiryTime));
    }, 1000);

    if (timeDiff < 0) {
      clearInterval(interval);
    }
    return (): void => {
      clearInterval(interval);
    };
  });

  if (timeDiff < 0) {
    return <Typography {...typographyProps}>{expiredLabel}</Typography>;
  }

  return (
    <Typography {...typographyProps}>
      {prefixLabel}{' '}
      <TextOrSkeleton>
        {timeDiff > 0 ? dayjs.duration(timeDiff).format(format) : undefined}
      </TextOrSkeleton>{' '}
      {suffixLabel}
    </Typography>
  );
}
