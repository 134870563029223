import { Typography } from '@mui/material';
import type { PpWC } from '../types';

export function AppHeaderTitle({ children }: PpWC): React.ReactElement {
  return (
    <Typography component="h1" textAlign="center" variant="paragraphBodyLBold">
      {children}
    </Typography>
  );
}
