import { LinkBehavior } from './LinkBehavior';

type PpGetinteractiveProps = {
  fallback?: 'div' | 'span';
  href?: string | undefined;
  onClick?: React.MouseEventHandler<HTMLElement>;
};
type TpGetinteractiveProps = {
  component: 'button' | 'div' | 'span' | typeof LinkBehavior;
  role?: 'button';
  type?: 'button' | null;
};
export function getInteractiveProps({
  fallback = 'span',
  href,
  onClick,
}: PpGetinteractiveProps = {}): TpGetinteractiveProps {
  if (href) {
    return { component: LinkBehavior };
  }

  if (onClick) {
    return { component: 'button', role: 'button', type: 'button' };
  }

  return { component: fallback };
}
