/* eslint-disable react/jsx-no-useless-fragment */
import { Fragment } from 'react';
import type { SkeletonProps } from '@mui/material';
import { Skeleton } from '@mui/material';
import { isUndefinedOrNull } from '@noah-labs/shared-tools/src/browser/utils';

export type PpTextOrSkeleton = SkeletonProps & {
  /**
   * children:
   * if null, undefined or false, the loading state is shown
   * if an empty string the 'fallback' is shown, defaults to an empty space to prevent layoutshift
   */
  children: React.ReactNode;
  fallback?: React.ReactNode;
  loading?: boolean;
  loadingFallback?: React.ReactNode;
  pretext?: React.ReactNode;
};

export function TextOrSkeleton({
  children,
  fallback,
  loading = false,
  loadingFallback,
  pretext,
  ...rest
}: PpTextOrSkeleton): React.ReactElement {
  if (loading || isUndefinedOrNull(children) || children === false) {
    return <Fragment>{loadingFallback || <Skeleton variant="text" {...rest} />}</Fragment>;
  }

  if (children === '') {
    return <Fragment>{fallback || '\u00A0'}</Fragment>;
  }

  return (
    <Fragment>
      {pretext}
      {children}
    </Fragment>
  );
}
