import { css } from '@emotion/react';
import { Container } from '@mui/material';
import type { ContainerProps } from '@mui/material';
import { cssMixins } from '../theme/cssMixins';

export function CenteredFullPage(props: ContainerProps): React.ReactElement {
  const styles = css`
    ${cssMixins.containerFullHeightOuter};
    ${cssMixins.colCentered};
  `;
  return <Container css={styles} maxWidth="md" {...props} />;
}
