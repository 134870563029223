import { EuropeanUnionIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/EuropeanUnionIcon';
import { UnitedKingdomIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/UnitedKingdomIcon';
import { UnitedStatesIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/UnitedStatesIcon';
import { mockFiatCurrenciesFromApi } from '@noah-labs/shared-currencies/src/fiat-currencies';
import type { FiatAmount, FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
// will remove deepmerge in next PR
import deepmerge from 'deepmerge';
import type { TpFiatCurrencyUI } from './types';

const currencyIcons: Partial<Record<FiatCurrencyCode, Pick<TpFiatCurrencyUI, 'Icon'>>> = {
  EUR: { Icon: EuropeanUnionIcon },
  GBP: { Icon: UnitedKingdomIcon },
  USD: { Icon: UnitedStatesIcon },
};

const fiatCurrenciesWithIcons = deepmerge(currencyIcons, mockFiatCurrenciesFromApi);

export function fiatCurrencyFromString(code: string | undefined): TpFiatCurrencyUI | undefined {
  if (typeof code !== 'string') {
    return undefined;
  }
  const codeUpper = code.toUpperCase();
  // Happy to use 'as' here because it will return undefined if it doesn't exist
  return fiatCurrenciesWithIcons[codeUpper as FiatCurrencyCode];
}

export function fiatCurrencyFromCode(code: FiatCurrencyCode): TpFiatCurrencyUI {
  return fiatCurrenciesWithIcons[code];
}

/**
 * Returns the fiat amount for the given currency code, or the first one if it doesn't exist
 */
export function fiatAmountForCurrency(
  amounts: FiatAmount[],
  currencyCode: FiatCurrencyCode
): FiatAmount {
  const amount = amounts.find((am) => am.FiatCurrency === currencyCode);
  return amount || amounts[0];
}
