/* eslint-disable react/jsx-no-useless-fragment */
import type { ReactElement } from 'react';
import React from 'react';
import { getTruncatedText } from './getTruncatedText';

export type PpTruncatedText = {
  prefix?: number;
  suffix?: number;
  text: string;
};

export function TruncatedText({ prefix, suffix, text }: PpTruncatedText): ReactElement {
  const truncatedText = getTruncatedText(text, { prefix, suffix });

  return <React.Fragment>{truncatedText}</React.Fragment>;
}
