import { css, keyframes } from '@emotion/react';
import { Box, Fade } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type PpProgressBar = {
  activeItem: number;
  index: number;
  isPaused: boolean;
};

export function StoryProgressBar({
  activeItem,
  index,
  isPaused,
}: PpProgressBar): React.ReactElement {
  const theme = useTheme();
  let progressStatus: 'full' | 'empty' | 'filling' = 'empty';

  const animation = keyframes`
    from{
      width: 0%;
    }
    to{
      width: 100%;
    }

  `;

  if (index === activeItem) {
    progressStatus = 'filling';
  }

  if (index < activeItem) {
    progressStatus = 'full';
  }

  const styles = {
    empty: css`
      width: 0%;
    `,
    filling: css`
      animation: ${animation} 9s linear forwards;
      animation-play-state: ${isPaused ? 'paused' : 'running'};
    `,
    full: css`
      width: 100%;
    `,
    progressItem: css`
      background-color: ${theme.palette.brand.main};
      height: 100%;
    `,
    progressItemWrapper: css`
      width: 100%;
      height: ${theme.spacing(0.25)};
      background-color: ${theme.palette.grayscale[300]};
      border-radius: ${theme.borderRadius.sm};
      overflow: hidden;
    `,
  };

  return (
    <Fade in={!isPaused} style={{ transitionDelay: '250ms' }} timeout={500}>
      <Box component="span" css={styles.progressItemWrapper}>
        <Box css={[styles.progressItem, styles[progressStatus]]} />
      </Box>
    </Fade>
  );
}
