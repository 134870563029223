import type { SVGProps } from 'react';

export function SvgUsdt(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="m5.028 2.713-4.012 8.426a.16.16 0 0 0 .035.19l10.835 10.382a.165.165 0 0 0 .228 0l10.834-10.38a.16.16 0 0 0 .035-.191l-4.011-8.426a.16.16 0 0 0-.148-.095H5.177a.16.16 0 0 0-.149.094"
        fill="#009393"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M13.392 12.005c-.078.005-.48.03-1.376.03-.713 0-1.22-.022-1.397-.03-2.755-.122-4.812-.601-4.812-1.175s2.057-1.054 4.812-1.177v1.874c.18.013.696.043 1.41.043.855 0 1.283-.035 1.36-.042V9.654c2.75.123 4.802.603 4.802 1.176 0 .572-2.052 1.052-4.802 1.174h.003Zm0-2.544V7.784h3.837V5.227H6.782v2.557h3.836V9.46c-3.118.143-5.463.76-5.463 1.501s2.345 1.357 5.463 1.501v5.374h2.773V12.46c3.111-.143 5.453-.76 5.453-1.5s-2.34-1.356-5.453-1.5Z"
        fill="#fff"
        fillRule="evenodd"
      />
    </svg>
  );
}
