import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import type { ListItemProps, ListItemTextProps } from '@mui/material';
import { ListItemAvatar, ListItem as MuiListItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DefaultListItemText } from './DefaultListItemText';

export type PpStructuredContent = Omit<ListItemProps<'div'>, 'component' | 'disablePadding'> & {
  Icon?: React.ReactNode;
  dataQa?: string;
  endText?: ListItemTextProps;
  iconCss?: SerializedStyles | undefined;
  listItem?: boolean;
  mainText: ListItemTextProps;
  noWrap?: boolean;
};

export function StructuredContent({
  dataQa,
  endText,
  Icon,
  iconCss,
  mainText,
  noWrap = true,
  ...rest
}: PpStructuredContent): React.ReactElement {
  const theme = useTheme();

  const styles = {
    defaultIconCss: css`
      margin-right: ${theme.spacing(1.25)};
    `,
    endText: css`
      flex: 1 1 auto;
      text-align: right;
      color: ${theme.palette.text.primary};
    `,
    mainText: css`
      min-width: fit-content;
      overflow: ${noWrap ? 'hidden' : undefined};
    `,
  };

  return (
    <MuiListItem {...rest} disablePadding component="div">
      {Icon && <ListItemAvatar css={[styles.defaultIconCss, iconCss]}>{Icon}</ListItemAvatar>}
      <DefaultListItemText
        css={styles.mainText}
        data-qa={dataQa ? `${dataQa}-main-text` : 'main-text'}
        noWrap={noWrap}
        {...mainText}
        primaryTypographyProps={{
          color: theme.palette.text.primary,
          variant: 'paragraphBodyMBold',
          ...mainText.primaryTypographyProps,
        }}
      />
      {endText && (
        <DefaultListItemText
          css={styles.endText}
          data-qa={dataQa ? `${dataQa}-end-text` : 'end-text'}
          noWrap={noWrap}
          {...endText}
        />
      )}
    </MuiListItem>
  );
}
