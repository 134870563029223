import type { SVGProps } from 'react';

export function SvgNoahCurrency(props: SVGProps<SVGSVGElement>): React.ReactElement {
  return (
    <svg
      {...props}
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M4.066 20a3.19 3.19 0 0 1-2.996-2.44C-.934 9.176.292 6.465 1.388 5.154a4.01 4.01 0 0 1 3.276-1.483h.02c1.973.11 3.374 1.626 5.493 3.917l.008.01.028.03c1.112 1.202 2.366 2.56 3.611 3.591 1.5 1.244 2.275 1.425 2.542 1.446q.081.004.161.013l.014-.001c.255-.453.778-1.818.901-5.387a3.18 3.18 0 0 1 1.686-2.703 3.2 3.2 0 0 1 3.191.11 3.18 3.18 0 0 1 1.495 2.812c-.18 5.201-1.143 8.312-3.142 10.09a5.57 5.57 0 0 1-3.976 1.436q-.111-.002-.222-.011l-.04-.003-.075-.007a3 3 0 0 1-.348 0l-.136-.008c-3.554-.272-6.63-3.116-9.201-5.83.135.821.328 1.785.598 2.91a3.18 3.18 0 0 1-.635 2.746A3.2 3.2 0 0 1 4.066 20"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
