import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export type PpStatusBadge = BoxProps & {
  Icon: React.ReactElement;
  badgeColor: string;
  badgeSize: number;
};

const StyledStatusBadge = styled(Box)<Omit<PpStatusBadge, 'Icon'>>(
  ({ badgeColor, badgeSize, theme }) => ({
    '& svg': {
      height: '100%',
      width: '100%',
    },
    color: badgeColor,
    height: theme.spacing(badgeSize),
    width: theme.spacing(badgeSize),
  })
);

export function StatusBadge({ Icon, ...props }: PpStatusBadge): React.ReactElement {
  return <StyledStatusBadge {...props}>{Icon}</StyledStatusBadge>;
}
