/* eslint-disable react/no-multi-comp */
import type { ReactElement } from 'react';
import type {
  ListItemButtonProps,
  ListItemProps,
  StackProps,
  TypographyProps,
} from '@mui/material';
import {
  ListItemIcon as MuiListIcon,
  ListItem as MuiListItem,
  ListItemButton as MuiListItemButton,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { InlineLinkOrButton } from '../buttons/InlineLinkOrButton';
import { ElevatedCard } from '../cards/ElevatedCard';

const StyledListItemButton = styled(MuiListItemButton)(() => ({
  '&:hover': {
    backgroundColor: 'transparent',
  },
  padding: 0,
  width: '100%',
})) as typeof MuiListItemButton;

export function ListItemButton({
  children,
  ...props
}: ListItemButtonProps & { href?: string }): ReactElement {
  return (
    <StyledListItemButton component={InlineLinkOrButton} {...props}>
      {children}
    </StyledListItemButton>
  );
}

export function ListItem({ children, ...props }: ListItemProps): ReactElement {
  return (
    <MuiListItem disablePadding {...props}>
      {children}
    </MuiListItem>
  );
}

export const ListItemCard = styled(ElevatedCard)({
  alignItems: 'center',
  display: 'flex',
  width: '100%',
});

export const ListItemContent = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'row',
  padding: theme.spacing(2),
  width: '100%',
}));

const StyledListItemStartContent = styled(Stack)({
  flex: 1,
  justifyContent: 'center',
});

export function ListItemStartContent({
  dataQa,
  ...props
}: StackProps & { dataQa?: string }): ReactElement {
  const dQa = dataQa ? `${dataQa}-start-text` : 'start-text';
  return <StyledListItemStartContent data-qa={dQa} {...props} />;
}

const StyledListItemEndContent = styled(Stack)({
  justifyContent: 'center',
  textAlign: 'right',
});

export function ListItemEndContent({
  dataQa,
  ...props
}: StackProps & { dataQa?: string }): ReactElement {
  const dQa = dataQa ? `${dataQa}-end-text` : 'end-text';
  return <StyledListItemEndContent data-qa={dQa} {...props} />;
}

export function ListItemPrimaryText({ children, ...props }: TypographyProps): ReactElement {
  return (
    <Typography variant="paragraphBodyMBold" {...props}>
      {children}
    </Typography>
  );
}

export function ListItemSecondaryText({ children, ...props }: TypographyProps): ReactElement {
  return (
    <Typography color="text.secondary" variant="paragraphBodyS" {...props}>
      {children}
    </Typography>
  );
}

export const ListItemIcon = MuiListIcon;
