type TpGetTruncatedTextOptions = {
  prefix?: number;
  suffix?: number;
};

export function getTruncatedText(text: string, options?: TpGetTruncatedTextOptions): string {
  const prefix = options?.prefix ?? 5;
  const suffix = options?.suffix ?? prefix;

  if (text.length <= prefix + suffix) {
    return text;
  }

  const partOne = text.slice(0, prefix);
  const partTwo = text.slice(text.length - suffix);

  return [partOne, partTwo].filter(Boolean).join('\u2026'); // ellipsis char
}
